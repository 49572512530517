import React, { useEffect, useContext, useRef } from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactContext from "../context/reactContext";
import "../styles/pages/blog-post.scss";

export default function BlogPostTemplate({ data }) {
  const { setCurrentBlogPost, setBlogPostAnchorIds } = useContext(ReactContext);
  const post = data.contentfulBlogPost;
  const anchorIdsRef = useRef([]);
  const blogImage = post.image[0].gatsbyImageData;

  function CustomH2({ node, children }) {
    const id =
      "section-" +
      encodeURIComponent(children.join(""))
        .replace(/%20/g, "-")
        .replace(/%3A/g, "-")
        .replace(/\./g, "_")
        .replace(/%3F/g, "-question-");
    anchorIdsRef.current.push(id);
    return <h2 id={id}>{children}</h2>;
  }

  useEffect(() => {
    setCurrentBlogPost(post.contentful_id)
  }, [])

  useEffect(() => {
    if (anchorIdsRef.current.length > 0) {
      setBlogPostAnchorIds([...anchorIdsRef.current]);
    }
  }, [setBlogPostAnchorIds]);


  return (
    <div className="db-blog-post">
      <article className="db-blog-post__article">
        <h1>{post.title}</h1>
        <GatsbyImage alt={post.title} image={blogImage} />
        <h4>{post.blogSummary}</h4>
        {/* <p>Author: {post.blogAuthor}</p> */}
        <ReactMarkdown
          components={{
            h2: CustomH2,
          }}
        >
          {post.postContent.postContent}
        </ReactMarkdown>
      </article>
    </div>
  );
}

export const query = graphql`
  query ($id: String!) {
    contentfulBlogPost(contentful_id: { eq: $id }) {
      blogAuthor
      blogSummary
      title
      contentful_id
      postContent {
        postContent
      }
      image {
        gatsbyImageData
      }
    }
  }
`;
